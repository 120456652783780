.arrowsContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.scrollList {
  height: 100%;
  overflow-y: auto;
}
