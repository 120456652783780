.loginWrapper {
  display: flex;
  justify-content: center;
  height: 100vh;
  background-color: #3670B6;
}
.loginBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 350px;
  height: 372px;
  margin-top: 80px;
  padding: 30px 0px;
  background-color: white;
  border-radius: 9px;
  margin-top: 105px;
}

.loginBox button span{
  text-transform: initial;
}

.header{
  font-size: 24px;
  margin: 15px 0px;
}
.smallText{
  font-size: 10px;
  color:#8E8C92;
  font-weight: 500;
  margin: 10px 0px 20px 0px;
  text-align: right;
}
.btnWrapper {
  position: relative;
  width: 100px;
  margin-left: 200px;
}
.errorAuthenticationText {
  color: #FD9728;
}