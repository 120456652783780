.inaccList {
  padding-left: 20px;
}

.accList {
  margin-left: -16px;
}

.listHeight {
  height: 22em;
}

.readonlyListHeight {
  height: 30em;
}
