.mdl a.dropdown-trigger {
  display: none;
}

.mdl .dropdown-content {
  z-index: 11;
  background: #fff;
  width: 99%;
  /* max-width: calc(100% - 32px); */
  max-height: 430px ;
}

.mdl .dropdown-content ul{ 
  padding-left: 0 !important;
}

.mdl li.node {
  width: auto;
    overflow: hidden;
    font-size: 14px;
    box-sizing: border-box;
    min-height: 48px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.5;
    padding-top: 6px;
    white-space: nowrap;
    letter-spacing: 0.00938em;
    padding-bottom: 6px;
}
.mdl li.leaf {
  padding-left: 75px !important;
}
.mdl li:hover {
  background-color: rgba(0, 0, 0, 0.14);
}
.mdl i.toggle {
  margin-right: 5px;
  margin-left: 10px;
}

.mdl .toggle {
  font: normal normal normal 18px/1 "Material Icons";
  color: rgba(0, 0, 0, 0.54);
  white-space: pre;
  margin-right: 4px;
}
.mdl .toggle:focus {
  outline: 0;
}
.mdl span.node-label {
  vertical-align: sub;
}

.mdl .toggle.collapsed::after {
  cursor: pointer;
  content: "\E5CF";
  vertical-align: middle;
}

.mdl .toggle.expanded::after {
  cursor: pointer;
  content: "\E5CE";
  vertical-align: middle;
}

/* checkbox styles */
.mdl .checkbox-item {
  position: relative;
  width: 1rem;
  height: 1rem;
  margin-right: 0.75rem;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0;
  vertical-align: middle;
}

.mdl .checkbox-item:before {
  font-family: 'Material Icons';
  content: url('../../../assets/check_box_outline_blank-24px.svg');
  opacity: 0.54;
  color: #29BB9C;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}
.mdl li.checked {
  background-color: rgba(0, 0, 0, 0.14);
}
.mdl .checkbox-item:checked:before {
  font-family: 'Material Icons';
  content: url('../../../assets/check_box-24px.svg');
  color: #29BB9C;
  opacity: 1;
}
