.timelineWrapper{
  box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
  background-color: white;
  width: calc(100vw - 290px);
}
.eventItem{
  position: sticky;
  left: 0px;
  padding-left: 5px;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  max-height: 100%;
}
.eventItemWrapper{
  border-radius: 8px;
}
.addButtonWrapper{
  min-width: 170px;
  margin-right: 15px;
}