.pageWrapper{
  display: flex;
  margin-top: 48px;
}
.menuWrapper{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 190px;
  width: 190px;
  background-color: #3670B6;
  color: white;
  box-shadow: 0px 3px 6px black;
}
.contentWrapper{
  width: calc(100vw - 190px);
  height: calc(100vh - 48px);
  overflow: auto;
}