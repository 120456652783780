.react-autosuggest__container {
  position: relative;
}
.react-autosuggest__input {
  width: calc(100% - 42px);
  padding: 10px 20px;
  font-size: 16px;
  border: 1px solid #D3D3D4;
  border-radius: 6px;
  color: #333333;
  outline: none;
}
.react-autosuggest__input--open {
  border-radius: 6px 6px 0px 0px;
}
.react-autosuggest__suggestions-container--open {
  position: absolute;
  top: 40px;
  z-index: 11;
  background-color: white;
  width: calc(100% - 2px);
  border: 1px solid #D3D3D4;
  border-top: none;
  border-radius: 0px 0px 6px 6px;
}
.react-autosuggest__suggestions-list{
  list-style-type: none;
  padding: 0px;
  margin: 0px;
}
.react-autosuggest__suggestion {
  margin: 10px 20px;
  cursor: pointer;
}