body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

button.Mui-disabled {
  opacity: 0.6 !important;
}

.centeredProgress {
  left: -20px;
  margin-left: 50%;
}

.iconWrapper {
  padding: 7px 7px 2px 7px;
  display: inline-block;
  border-radius: 50%;
}
.iconWrapper:hover {
  background-color: #f3f3f4;
}

.iconWrapper-noHover:hover {
  background-color: #ffffff !important;
}

.loadingSpinner {
  margin-left: 50%;
  padding: 30px;
}

.tableWithFixedFirstColumn:first-child tr th {
  z-index: 998;
}

.tableWithFixedFirstColumn:first-child tr th:first-child {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 999;
}

.tableWithFixedFirstColumn:first-child tr td:first-child,
.tableWithActionsContainer:first-child tr td:last-child {
  position: sticky;
  right: 0;
  z-index: 995;
}

.tableWithActionsContainer tr:not(.inlineEditRow) > td:last-child button {
  visibility: hidden;
}

.tableWithActionsContainer tr:hover > td:last-child button {
  visibility: visible;
}

.tableWithActionsContainerRequest tr > td > div {
  justify-content: flex-end;
}

/* Adding `clear` property to the td element is a hack to target the sibling table cell */
.tableWithActionsContainerRequest tr > td[style*="clear: unset"] + td > div > span:first-child,
.tableWithActionsContainerRequest tr > td[style*="clear: none"] + td > div > span {
  display: none;
}

h3,
h5 {
  font-weight: normal;
  margin: 0;
}
a {
  color: #29bb9c;
}

.pageWrapper {
  padding: 8px 40px 40px 40px;
  background-color: #d3d3d4;
  min-height: calc(100% - 50px);
}

.MuiDialogTitle-root h2 {
  font-size: 1.5rem;
  padding-top: 8px;
}

tbody > tr:hover {
  background-color: #ebf9f6 !important;
}

tbody > tr {
  opacity: 1 !important;
}

.MuiPickersBasePicker-pickerView {
  padding-bottom: 16px;
}
