.react-calendar-timeline .rct-header-root {
  background: #fff;
  position: sticky;
  top: 0px;
  z-index: 100;
}
.react-calendar-timeline .rct-header-root:after {
  content: '';
  position: absolute;
  top: 0px;
  left: 200px;
  width: 1px;
  height: 100%;
  background-color: #bbb;
}
.react-calendar-timeline .rct-dateHeader {
  border-left: 1px solid #bbb;
  border-right: none;
  border-bottom: none;
}
.react-calendar-timeline .rct-dateHeader-primary {
  color: #333333;
  border-bottom: 1px solid #bbb;
}
.react-calendar-timeline .rct-calendar-header {
  border: none;
}
.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-vl-first {
  border-left-width: 1px; 
}
.react-calendar-timeline .rct-sidebar {
  border-right: none;
}
.react-calendar-timeline .rct-sidebar .rct-sidebar-row{
  padding: 0px;
}
.react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
  background: initial;
}
.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-6, 
.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-0,
.holiday {
  background: rgba(0, 0, 0, 0.1);
}
.react-calendar-timeline .rct-scroll{
  overflow-x: hidden;
}
.custom-group {
  display: flex;
  justify-content: space-between;
  padding: 0px 4px;
}
.custom-group.highlighted{
  background-color: #ebf9f6;
}
.tip {
  color: gray;
}