.wrapper{
  display: flex;
  align-items: flex-end;
  position: relative;
}
.colorLabel {
  font-size: 16px;
  margin-right: 5px;
  margin-bottom: 3px;
}
.colorBoxWrapper {
  padding: 5px;
  background: #fff;
  border-radius: 1px;
  box-shadow: 0 0 0 1px rgba(0,0,0,.1);
  cursor: pointer;
}
.colorBox {
  width: 50px;
  height: 16px;
  border-radius: 2px;
}
.popover {
  position: absolute;
  top: 50px;
  z-index: 2;
}
.cover {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}