.titleWrapper {
  display: flex;
  justify-content: space-between;
  font-size: 1.5rem;
}
.titleText{
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.statusLabel{
  margin-right: 5px;
  font-weight: 500;
}
.statusIndicator{
  margin-left: 5px;
  margin-right: 18px;
}